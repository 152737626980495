<template>
  <div>
    <KycBarContainer />
    <div
      class="page flex flex-grow flex-shrink-0 flex-col bg-main-background dark:bg-custom-purple-500"
    >
      <HeaderContainer
        v-if="isMobile"
        class="w-full"
        :class="globalPadding"
      />
      <div v-else>
        <HeaderContainer v-if="usersStore.user && usersStore.user.id && usersStore.user.profile_type !== 'App\\Models\\GuestUser'" />
        <FrontHeaderContainer
          v-else
        />
      </div>
      <main class="flex flex-col flex-grow flex-shrink-0 z-10 mb-16">
        <RouterView :key="$route.fullPath" />
      </main>
      <FooterContainer
        v-if="usersStore.user && !isGuest"
        :padding="footerPadding"
      />
      <FrontFooterContainer v-if="$route.meta.needLogin !== false || !(parentRoute !== 'chat' && isMobile)" />
      <!-- <FairUsageModalContainer v-if="((user && user.id && user.profile_type === 'App\\Models\\GuestUser' || !user || !user.id) && $route.meta.needLogin !== false) || (!(user && user.id && user.profile_type !== 'App\\Models\\GuestUser') && $route.meta.needLogin !== false)" /> -->
      <PortalTarget
        name="dialog"
        multiple
      />
    </div>
  </div>
</template>

<script>

import FrontHeaderContainer from '@/modules/frontHeader'
import HeaderContainer from '@/modules/header'
import FooterContainer from '@/modules/footer'
// import FairUsageModalContainer from '@/modules/fairUsageModal'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import usersMixin from '@/mixins/usersMixin'
import FrontFooterContainer from '@/modules/frontFooter'
import { PortalTarget } from 'vue3-portal'
import { useUsersStore } from '@/stores/users'
import { useAppsStore } from '@/stores/app'
import { isMobile } from '../js/helpers/mobile'
import KycBarContainer from '@/modules/kycBar'

export default {
  name: 'FrontLayout',
  components: {
    HeaderContainer,
    FooterContainer,
    FrontHeaderContainer,
    FrontFooterContainer,
    PortalTarget,
    KycBarContainer
  },
  mixins: [headerPaddingMixin, usersMixin],
  setup () {
    const usersStore = useUsersStore()
    const appsStore = useAppsStore()

    return { usersStore, appsStore }
  },
  computed: {
    isKycPending() {
      return this.usersStore?.user?.profile?.is_verified && this.usersStore?.user?.is_kyc_pending
    },
    isLoggedIn() {
      return this.usersStore.isLoggedin
    },
    isMobile () {
      return isMobile()
    },
    globalPadding () {
      if (!this.appsStore.newVersionAvailable) { // shall only change for header
        return this.platformPadding
      }

      return ''
    },
    iPhoneMargin(){
      return this.appStore?.iPhoneMargin
    },
    footerPadding () {
      return this.platformPadding
    },
    parentRoute () {
      if (this.$route.matched.length > 1) {
        return this.$route?.matched[0]?.name
      }

      return this.$route.name
    },
  }
}
</script>
